<template>
    <defaultSec :title="'在职继承'">
        <keep-alive>
            <el-form :inline="true" class="demo-form-inline select-wrap" size="mini">
                <el-form-item>
                    <el-button type="success" @click="transfer()">转移</el-button>
                </el-form-item>
            </el-form>
        </keep-alive>
        <el-table :data="dataList" border size="mini" v-loading="loading" element-loading-text="统计中，请稍后...">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column label="企微号" prop="qwId" width="180"></el-table-column>
            <el-table-column label="企微" prop="qwName" width="190"></el-table-column>
            <el-table-column label="用户数" prop="addCount" width="150"></el-table-column>
            <el-table-column label="可转移数量" prop="canTransferCount" width="230"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-radio-group size="mini" v-model="scope.row.radio">
                        <el-radio-button :label="0">不操作</el-radio-button>
                        <el-radio-button :label="1" :disabled="scope.row.canTransferCount <= 0">转出</el-radio-button>
                        <el-radio-button :label="2">转入</el-radio-button>
                    </el-radio-group>
                </template>
            </el-table-column>
            <el-table-column label="数量">
                <template slot-scope="scope">
                    <el-input-number size="mini" v-model="scope.row.transferCount" @change="handleChange" :min="0" :max="scope.row.radio==1?scope.row.canTransferCount:999999" label="数量"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" plain size="mini" @click="quit(scope.row.qwId)">改为离职</el-button>
                </template>
            </el-table-column>
        </el-table>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
export default {
    data() {
        return {
            dataList: [],
            showDialog: false,
            loading: true
        }
    },
    components: {
        defaultSec
    },
    mounted(){
        this.initList()
    },
    methods: {
        initList() {
            this.dataList = [];
            this.$apiGet('lechun-cms/qiyeweixin/qwListForTransfer', this.formParam).then(res => {
                console.log(res)
                this.loading = false
                this.dataList = res.map((item)=>{
                    item.radio = 0
                    item.transferCount = 0
                    return item
                })
            });
        },
        transfer(){
            console.log("this.dataList",this.dataList);

            // 校验转入转出数量是否相等
            // const handOverIds = "aaaa--1000__bbbb--1200__cccc-800";
            // const takeOverIds = "xxxx--1500__yyyy--1300__zzzz-200";
            let handOverIds = "";
            let takeOverIds = "";
            let handOverCount = 0;
            let takeOverCount = 0;
            this.dataList.forEach((data) => {
                if(data.radio == 1 && data.transferCount > 0){
                    handOverIds = handOverIds + data.qwId + "--" + data.transferCount + "__";
                    handOverCount = handOverCount + data.transferCount;
                } else if(data.radio == 2 && data.transferCount > 0){
                    takeOverIds = takeOverIds + data.qwId + "--" + data.transferCount + "__";
                    takeOverCount = takeOverCount + data.transferCount;
                }
            })
            if(handOverCount <= 0 || takeOverCount <= 0 || handOverCount != takeOverCount){
                this.$message({
                    type: 'error',
                    message: '转入转出数量必须相等且大于0'
                });
                return
            }
            console.log('handOverIds',handOverIds);
            console.log('takeOverIds',takeOverIds);
            console.log('url','lechun-cms/qiyeweixin/manualTransferCustomer');
            this.$apiGet('lechun-cms/qiyeweixin/manualTransferCustomer', {'handOverIds':handOverIds,'takeOverIds':takeOverIds}).then(res => {
                this.showDialog = false;
            });
        },
        quit(qwId){
            this.$confirm('确定已经在企微后台操作为离职了吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // this.$apiGet('lechun-csms/orderApi/orderFlow', {'orderNo': this.orderDetailInfo.orderNo}).then(res => {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.initList();
                // });
            }).catch(() => {

            });
        }
    }
}
</script>

<style scoped>
.expand-title {
    width: 70px;
    color: #444548;
    line-height: 30px;
    text-align: right;
    margin-right: 10px;
    font-weight: 600;
}

.expand-flex {
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
</style>